import React from 'react'
import EditButton from '../../molecules/buttons/EditButton'
import DeleteButton from '../../molecules/buttons/DeleteButton'
import { LanguageSelect } from '../../molecules/LanguageSelect'
import Tr from '../../atoms/table/Tr'
import Th from '../../atoms/table/Th'
import Td from '../../atoms/table/Td'
import Table from '../../atoms/table/Table'
import PropTypes from 'prop-types'

const TranslationsTable = ({
  translations,
  availableLanguages,
  toNew,
  toEdit,
  onEdit,
  onDelete,
  onLocaleChange,
  selectedLocale
}) => {
  const translationsWithoutSpanish = translations.filter(translation => translation.locale !== 'es')
  return (
    <Table
      spacing="normal"
      className="table-fixed md:w-full w-800"
      values={translations}
      head={
        <Tr body={false}>
          <Th className="w-24 md:pl-0">Idiomas adicionales</Th>
          <Th className="text-right w-24 md:pr-6">Acciones</Th>
        </Tr>
      }
      body={
        <>
          {translationsWithoutSpanish.map((translation, index) => (
            <Tr key={index} className="bg-neutral-200">
              <Td>
                <LanguageSelect languages={translations} disabled value={translation.locale} />
              </Td>
              <Td className="text-right">
                <EditButton to={toEdit + `?locale=${translation.locale}`} />
                <DeleteButton onClick={() => onDelete(translation.locale, translation.name)} />
              </Td>
            </Tr>
          ))}
          <Tr className="bg-gray-200">
            <Td className="md:p-4">
              <LanguageSelect languages={availableLanguages} onLocaleChange={onLocaleChange} value={selectedLocale} />
            </Td>
            <Td className="text-right">
              <EditButton to={toNew || '#'} onClick={onEdit} />
              <DeleteButton onClick="#" />
            </Td>
          </Tr>
        </>
      }
    />
  )
}

export default TranslationsTable

TranslationsTable.propTypes = {
  translations: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string.isRequired,
      name: PropTypes.string
    })
  ).isRequired,
  availableLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  toNew: PropTypes.string,
  toEdit: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onLocaleChange: PropTypes.func.isRequired,
  selectedLocale: PropTypes.string
}
