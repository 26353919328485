import PropTypes from 'prop-types'
import React, { Fragment, isValidElement } from 'react'

import clsx from 'clsx'
import Error from '../Error'
import Hint from '../../atoms/Hint'
import { marginSizes } from '../../../configs/sizesConfig'
import { useCustomField } from '../../../hooks/useCustomField'

const sizeMap = {
  full: 'w-full',
  '5xl': 'sm:max-w-xl',
  '4xl': 'sm:max-w-lg',
  '3xl': 'sm:max-w-md',
  '2xl': 'sm:max-w-sm',
  xl: 'sm:max-w-xs',
  lg: 'sm:max-w-64',
  md: 'sm:max-w-48',
  sm: 'sm:max-w-28'
}

const parseLabel = label => {
  if (isValidElement(label)) return label
  const textDividedByParenthesis = label?.split(/\(([^)]+)\)/)
  if (textDividedByParenthesis.length > 1) {
    return (
      <Fragment>
        {textDividedByParenthesis.map((text, index) => (
          <span key={text} className={index % 2 ? 'font-normal' : ''}>
            {index % 2 ? `(${text})` : text}
          </span>
        ))}
      </Fragment>
    )
  }
  return label
}

const TextField = ({
  name,
  label,
  className,
  rootClassName,
  validate,
  hint,
  size = 'xl',
  disabled = false,
  element: Element = 'input',
  orientation = 'vertical',
  margin = 'normal',
  leftDecorator,
  rows,
  labelClassName,
  errorClassName,
  rightDecorator: RightDecorator,
  maxLength,
  readOnly = false,
  rigthTextDecorator,
  dataTestid,
  hideError = false,
  ariaLabel,
  onChange,
  ...props
}) => {
  const { input, meta, gotError } = useCustomField(name, { parse: a => a, validate, ...props })
  const isHorizontal = orientation === 'horizontal'

  const hasRigthDecoratorAndNoErrors = RightDecorator && !meta.error

  return (
    <div
      className={clsx(
        marginSizes[margin],
        'relative',
        rootClassName,
        isHorizontal ? 'flex items-baseline' : '',
        'js-field-container'
      )}
    >
      {label && (
        <label
          className={clsx('block font-bold mb-2', { 'mr-4': isHorizontal }, labelClassName)}
          id={`${name}-label`}
          htmlFor={`${name}-input`}
          aria-describedby={`${name}-hint`}
        >
          {parseLabel(label)}
        </label>
      )}
      {hint && <Hint hint={hint} name={name} />}
      <div className={clsx({ relative: isHorizontal }, leftDecorator && 'flex items-center')}>
        {leftDecorator}
        <div className=" flex flex-nowrap md:flex-wrap items-center">
          <Element
            id={`${name}-input`}
            data-testid={dataTestid}
            className={clsx(
              'border-2 border-gray-800 shadow py-2 px-4 rounded bg-white focus:outline-none',
              hasRigthDecoratorAndNoErrors ? 'w-4/5' : 'w-full',
              sizeMap[size],
              className,
              gotError ? 'focus-within:border-red-500 border-red-500' : 'focus-within:border-yellow-500',
              rows ? 'h-auto' : 'h-12'
            )}
            aria-label={ariaLabel}
            aria-describedby={`${name}-hint`}
            disabled={disabled}
            rows={rows}
            placeholder={props.placeholder}
            maxLength={maxLength}
            readOnly={readOnly}
            {...input}
            onChange={e => {
              input.onChange(e.target.value)
              onChange && onChange(e.target.value)
            }}
          />
          {hasRigthDecoratorAndNoErrors && <RightDecorator inputValue={input.value} />}
          {rigthTextDecorator && <span className="ml-4 ">{rigthTextDecorator}</span>}
        </div>
        <div className="flex">
          {!hideError && <Error name={name} className={clsx('mb-4 ', errorClassName)} />}
          {Element !== 'input' && maxLength && (
            <p className={clsx('absolute font-normal text-sm text-gray-700 right-0')}>
              {input.value.length}/{maxLength}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default TextField

TextField.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  dataTestid: PropTypes.string,
  disabled: PropTypes.bool,
  element: PropTypes.string,
  errorClassName: PropTypes.string,
  hideError: PropTypes.bool,
  hint: PropTypes.node,
  label: PropTypes.node,
  labelClassName: PropTypes.string,
  leftDecorator: PropTypes.element,
  margin: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  orientation: PropTypes.string,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  rightDecorator: PropTypes.element,
  rigthTextDecorator: PropTypes.string,
  rootClassName: PropTypes.string,
  rows: PropTypes.number,
  size: PropTypes.string,
  validate: PropTypes.func,
  onChange: PropTypes.func
}
