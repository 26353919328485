import React from 'react'
import Exports from '../../../templates/Exports'
import useExport from '../../../../hooks/useExport'

const filterLabel = {
  search: 'Nombre de trámite',
  status: 'Estado',
  enabled_date: 'Fecha de habilitación',
  procedure_management_name: 'Trámite',
  institution_name: 'Entidad'
}
const EnabledProcedureManagementExport = () => {
  const { fetchExportByType } = useExport()
  const fetchExports = fetchExportByType('enabled_procedure_managements')

  const exportDescription = `Estos son los archivos exportados sobre las gestiones de trámites habilitadas.`
  return <Exports fetchExports={fetchExports} exportDescription={exportDescription} filterLabel={filterLabel} />
}

export default EnabledProcedureManagementExport
