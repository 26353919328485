import PropTypes from 'prop-types'
import React from 'react'
import UbigeoField from '../question_config_fields/UbigeoField'
import MapIcon from '@material-ui/icons/Map'
import UbigeoCitizenField from '../question_fields/UbigeoCitizenField'
import BoldLabelAndContent from '../../molecules/BoldLabelAndContent'

const Form = props => {
  return <UbigeoField baseName={props.baseName} />
}

const Template = props => {
  return <UbigeoCitizenField payload={props.question.payload} name={props.name} disabled />
}

const Answer = props => {
  const content = getArrayToShow(props).map((item, key) => <p key={`${props.question.id}_${key}`}>{item}</p>)
  return <BoldLabelAndContent label={props.question.label} content={content} el="li" />
}

const TaskResponse = props => {
  const content = getArrayToShow(props).map((item, key) => <p key={`${props.question.id}_${key}`}>{item}</p>)
  return <BoldLabelAndContent label={props.question.label} content={content} className="mb-2" el="li" />
}

const getArrayToShow = ({ answer, question }) => {
  const content = [
    `${question.payload.ubigeo_region_label}: ${answer.region_name}`,
    `${question.payload.ubigeo_city_label}: ${answer.province_name}`,
    `${question.payload.ubigeo_district_label}: ${answer.district_name}`
  ]
  return content
}

const UbigeoQuestion = {
  selectOption: { value: 'ubigeo', label: 'Ubigeo', icon: <MapIcon fontSize="small" /> },
  Form,
  Template,
  Answer,
  TaskResponse,
  name: 'UbigeoQuestion'
}

export default UbigeoQuestion

Form.propTypes = {
  baseName: PropTypes.string
}

Template.propTypes = {
  name: PropTypes.string,
  question: PropTypes.object.isRequired
}

Answer.propTypes = {
  question: PropTypes.object.isRequired
}

TaskResponse.propTypes = {
  question: PropTypes.object.isRequired
}
