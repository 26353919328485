import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { AccordionDetails } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { AnswerLines } from '../answers/AnswerLines'
import TaskHistoryTitleRight from './TaskHistoryTitleRight'
import TagState from './TagState'
import TaskHistoryLeafButton from './TaskHistoryLeafButton'
import TaskHistoryReverseTaskButton from './TaskHistoryReverseTaskButton'
import MuiAccordion from '@material-ui/core/Accordion'
import PaymentMethodForm from '../payments/PaymentMethodForm'
import Alert from '../../molecules/Alert'
import clsx from 'clsx'
import TaskHistoryOriginDestinyByTaskKind from './TaskHistoryOriginDestinyinfo'
import ManualEntryPayment from '../payments/ManualEntryPayment'
import { compareByCompleteDate } from '../../../utils/helpers'

const Accordion = withStyles({
  root: {
    padding: '16px 24px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&::before': {
      height: 0
    },
    '&$expanded': {
      marginTop: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    position: 'relative'
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {},
  expandIcon: {
    position: 'absolute',
    right: 0,
    bottom: '12px'
  }
})(MuiAccordionSummary)

const TaskHistory = ({
  title = 'Historial de tareas',
  taskExecutions,
  taskRuleExecutions = [],
  selectedLeafId,
  setSelectedLeafId,
  onReverseTask,
  onUpdatePaymentState,
  contactMedium
}) => {
  const taskExecutionsList = useMemo(() => {
    const unorderedTaskExecution = [...taskExecutions, ...taskRuleExecutions]
    return unorderedTaskExecution.sort(compareByCompleteDate)
  }, [taskExecutions, taskRuleExecutions])

  const leafCount = taskExecutionsList.filter(taskExecution => taskExecution.is_leaf).length
  const stateCount = taskExecutionsList.filter(taskExecution => taskExecution.state).length
  const [expanded, setExpanded] = useState(taskExecutionsList.length - 1)
  useEffect(() => setExpanded(taskExecutionsList.length - 1), [taskExecutionsList])
  const onChange = index => (event, newExpanded) => {
    setExpanded(newExpanded ? index : false)
  }

  return (
    <div className="mb-6">
      <div className="bg-neutral-200 px-6 py-4 flex justify-between">
        <div className="font-bold">{title}</div>
        {(leafCount > 1 || selectedLeafId) && (
          <TaskHistoryTitleRight leafId={selectedLeafId} onShowAll={() => setSelectedLeafId(null)} />
        )}
      </div>
      {taskExecutionsList.map((taskExecution, index) => (
        <Accordion
          key={index}
          className="bg-neutral-100 mb-4"
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded === index}
          onChange={onChange(index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize="large" />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
          >
            <div className="w-full">
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-3 gap-3">
                {stateCount > 1 && taskExecution.state ? (
                  <TagState state={taskExecution.state} className="mb-3" />
                ) : (
                  <div />
                )}
                <div className="flex flex-col gap-2 md:items-end">
                  {(leafCount > 1 || selectedLeafId) && taskExecution.is_leaf && (
                    <TaskHistoryLeafButton
                      leafId={selectedLeafId}
                      onShowBranch={() => setSelectedLeafId(taskExecution.id)}
                      onShowAll={() => setSelectedLeafId(null)}
                    />
                  )}
                  {taskExecution.is_reversible && (
                    <TaskHistoryReverseTaskButton
                      onReverseTask={() => onReverseTask(taskExecution.id)}
                      destinyUserLabel={taskExecution.destiny_user_label}
                    />
                  )}
                </div>
              </div>
              <p>{taskExecution.created_at}</p>
              <div className="font-bold text-lg">
                {(taskExecution.index || index) + 1}. {taskExecution.name}
              </div>
              <TaskHistoryOriginDestinyByTaskKind taskExecution={taskExecution} />
            </div>
          </AccordionSummary>
          <AccordionDetails className="border-t-2 border-neutral-200 block px-0 py-4">
            {taskExecution.message_from_execution ? (
              <div
                className="hint-html-injected"
                dangerouslySetInnerHTML={{ __html: taskExecution.message_from_execution }}
              />
            ) : (
              <>
                {(taskExecution.citizen_contact_medium_notified_at_date ||
                  taskExecution.citizen_tracking_update_visualized_at_date) && (
                  <Alert hideIcon type="info" className="mb-6">
                    <ul
                      className={clsx(
                        'pl-4 text-sm',
                        taskExecution.citizen_contact_medium_notified_at_date &&
                          taskExecution.citizen_tracking_update_visualized_at_date
                          ? 'list-disc'
                          : 'list-none'
                      )}
                    >
                      {taskExecution.citizen_contact_medium_notified_at_date && (
                        <li>
                          Ciudadano/a fue notificado/a por {contactMedium} y se actualizó su página de Seguimiento de
                          solicitud el <strong>{taskExecution.citizen_contact_medium_notified_at_date}</strong> a las{' '}
                          <strong>{taskExecution.citizen_contact_medium_notified_at_time} hrs</strong>.
                        </li>
                      )}
                      {taskExecution.citizen_tracking_update_visualized_at_date && (
                        <li className="mt-2">
                          Ciudadano/a visualizó la actualización en su página de Seguimiento de solicitud el{' '}
                          <strong>{taskExecution.citizen_tracking_update_visualized_at_date}</strong> a las{' '}
                          <strong>{taskExecution.citizen_tracking_update_visualized_at_time} hrs</strong>.
                        </li>
                      )}
                    </ul>
                  </Alert>
                )}
                <ol className="list-inside list-sub_index-decimal-bold ml-5">
                  <AnswerLines data={taskExecution.answer_lines} dispatch="TaskResponse" subChildrenClassName="mt-2" />
                </ol>
                {taskExecution.has_manual_entry_payment && <ManualEntryPayment answer={taskExecution.payment_info} />}
                {taskExecution.payment_info?.selected_kind_payment && (
                  <PaymentMethodForm
                    paymentMethod={taskExecution.payment_info.selected_kind_payment}
                    kind="taskExecutionAnswer"
                    answer={taskExecution.payment_info}
                    hideConfirmForm={!taskExecution.is_current_destiny}
                    onUpdateState={onUpdatePaymentState}
                  />
                )}
              </>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}
export default TaskHistory

TaskHistory.propTypes = {
  contactMedium: PropTypes.string,
  onReverseTask: PropTypes.func,
  onUpdatePaymentState: PropTypes.func,
  selectedLeafId: PropTypes.number,
  setSelectedLeafId: PropTypes.func,
  taskExecutions: PropTypes.arrayOf(PropTypes.object),
  taskRuleExecutions: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string
}
