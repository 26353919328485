import PropTypes from 'prop-types'
import PageHeading from '../../molecules/PageHeading'
import React from 'react'
import AdminTable from './AdminTable'
import { ReactSVG as Svg } from 'react-svg'
import xlsSvg from '../../../images/icons/icon-xls.svg'
import { BoxTable } from '../../molecules/BoxTable'
import EnabledProcedureManagementsFilter from './EnabledProcedureManagementsFilter'
import ExportButton from '../exports/ExportButton'
import AllInstitutionsBranchOfficeFilter from '../filters/AllInstitutionsBranchOfficeFilter'

const AdminPage = ({ enabledProcedureManagements, onFilter, initialFilters, filters, options }) => {
  return (
    <>
      <PageHeading
        title="Gestiones de trámites habilitadas"
        pageDescription="Selecciona la entidad cuyas gestiones de trámites habilitadas quieres ver."
        customButton={
          <ExportButton
            className="mt-auto ml-auto"
            exportable={{ type: 'enabled_procedure_managements' }}
            title={
              <span className="flex items-center">
                Exportar archivo <Svg src={xlsSvg} className="ml-1" />
              </span>
            }
            url="/gestiones-de-tramites-habilitadas/exportaciones"
            filters={filters}
          />
        }
        filterSection={<AllInstitutionsBranchOfficeFilter byType="by_enabled_procedure_management" />}
      />
      <BoxTable
        filters={<EnabledProcedureManagementsFilter />}
        onFilter={onFilter}
        initialFilters={initialFilters}
        sortOptions={options}
        values={enabledProcedureManagements}
        searchPlaceholder="Buscar por nombre de trámite"
        displayTotal
        displaySort
        displayHeader={false}
        accordionHasBackground={false}
      >
        <AdminTable enabledProcedureManagements={enabledProcedureManagements.data} />
      </BoxTable>
    </>
  )
}

export default AdminPage

AdminPage.propTypes = {
  enabledProcedureManagements: PropTypes.object,
  filters: PropTypes.object,
  initialFilters: PropTypes.object,
  onFilter: PropTypes.func,
  options: PropTypes.array
}
