import React from 'react'
import useFilters from '../../../../hooks/useFilters'
import axios from 'axios'
import { useQueryClient } from 'react-query'
import PageHeading from '../../../molecules/PageHeading'
import AddIcon from '@material-ui/icons/Add'
import ProcedureManagementsTable from '../../../organisms/forms/ProcedureManagementsTable'
import LaunchIcon from '@material-ui/icons/Launch'
import { BoxTable } from '../../../molecules/BoxTable'
import ProcedureManagementFilter from '../../../organisms/procedure_managements/ProcedureManagementFilter'

const ProcedureManagements = () => {
  const {
    initialFilters,
    results: procedureManagements,
    onFilter
  } = useFilters(`/admin/v1/procedure_managements.json`, { sort: 'created_at desc' })
  const queryClient = useQueryClient()
  const handleDestroy = async procedureManagement => {
    if (window.confirm(`¿Estás seguro que deseas eliminar la gestión de trámite ${procedureManagement.name}?`)) {
      try {
        await axios.delete(`/admin/v1/procedure_managements/${procedureManagement.id}.json`)
        queryClient.invalidateQueries([`/admin/v1/procedure_managements.json`])
      } catch (error) {
        window.alert(error.response.data.message)
      }
    }
  }
  const optionsOrderBy = [
    { value: 'created_at desc', label: 'Creación: más recientes primero' },
    { value: 'created_at asc', label: 'Creación: más antiguos primero' },
    { value: 'updated_at desc', label: 'Actualización: más recientes primero' },
    { value: 'updated_at asc', label: 'Actualización: más antiguos primero' }
  ]
  return (
    <>
      <PageHeading
        title="Gestión de trámites"
        pageDescription="Estos son los trámites que las entidades podrán gestionar en Facilita."
        button={{ link: '/gestiones-de-tramites/crear', name: 'Crear nueva gestión de trámite', icon: <AddIcon /> }}
        linksSection={
          <div className="text-base md:text-lg">
            <a
              href="/admin/gestiones-de-tramites/que-es-un-gestor-de-tramites"
              className="font-bold flex md:block mb-6"
            >
              <span className="flex-1">¿En qué consiste la gestión de un trámite?</span>
              <LaunchIcon className="ml-2" />
            </a>
            <a
              href="/admin/gestiones-de-tramites/como-se-crea-un-gestor-de-tramites"
              className="font-bold flex md:block"
            >
              <span className="flex-1">¿Cómo se crea una gestión de trámite?</span>
              <LaunchIcon className="ml-2" />
            </a>
          </div>
        }
      />
      <BoxTable
        filters={<ProcedureManagementFilter />}
        onFilter={onFilter}
        initialFilters={initialFilters}
        values={procedureManagements}
        sortOptions={optionsOrderBy}
        displayTotal
        displaySort
        displayHeader={false}
        accordionHasBackground={false}
      >
        <ProcedureManagementsTable procedureManagements={procedureManagements.data} onDestroy={handleDestroy} />
      </BoxTable>
    </>
  )
}
export default ProcedureManagements
