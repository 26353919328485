import PropTypes from 'prop-types'
import React from 'react'
import Container from '../../atoms/Container'
import Wizard from './Wizard'
import { InitialForm } from './InitialForm'
import { GeneralConfigurationStep } from './GeneralConfigurationStep'
import { FormTypeContext } from '../../../utils/contexts'
import { PageNavigation } from '../navigation/PageNavigation'
import { createHeaderInfo } from '../../../configs/procedureManagementsConfig'

const editHeaderInfo = name => ({
  title: `Editando trámite: ${name}`,
  hint: 'Editar una gestión de trámite para las instituciones usuarias de Facilita.'
})

export const ProcedureManagementForm = ({ initialValues, mutate, formSteps, create = false, step = 1 }) => {
  const stepPages = [<GeneralConfigurationStep key="0" />, <InitialForm key="1" />]
  const headerInfoText = create ? createHeaderInfo : editHeaderInfo(initialValues?.name)

  return (
    <>
      <PageNavigation />
      <Container className="pb-12 md:pt-16 pt-10">
        <FormTypeContext.Provider value={'procedure_management'}>
          <Wizard
            initialValues={initialValues}
            mutateForm={mutate}
            stepPages={stepPages}
            headerInfo={headerInfoText}
            stepLabels={Object.values(formSteps)}
            defaultStep={step}
          />
        </FormTypeContext.Provider>
      </Container>
    </>
  )
}

ProcedureManagementForm.propTypes = {
  create: PropTypes.bool,
  formSteps: PropTypes.object,
  initialValues: PropTypes.object,
  mutate: PropTypes.func.isRequired,
  step: PropTypes.number
}
