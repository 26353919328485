import PropTypes from 'prop-types'
import React from 'react'
import LightTooltip from '../../molecules/LightTooltip'
import InfoIcon from '@material-ui/icons/Info'
import { ReactSVG as Svg } from 'react-svg'
import arrowRight from '../../../images/icons/arrow_right.svg'
import arrowDown from '../../../images/icons/arrow_down.svg'
import SelectField from '../../molecules/fields/SelectField'
import { dataToOptions } from '../../../utils/helpers'
import * as validations from '../../../utils/validations'
import StateSection from './StateSection'
import useUserTypes from '../../../hooks/useUserTypes'
import { useForm } from 'react-final-form'
import { defaultUserType, getOriginAndDestinySectionLabel } from '../../../configs/procedureManagementsConfig'
import useFieldValue from '../../../hooks/useFieldValue'
import { isEnablePayment } from '../../../hooks/useTask'

const OriginAndDestinySection = ({ task }) => {
  const originUserTypeId = useFieldValue('origin_user_type_id')
  const { change } = useForm()
  const procedureManagementId = useFieldValue('procedure_management_id')
  const { data: userTypes, status } = useUserTypes(procedureManagementId)
  if (status !== 'success') return null
  const canSameDestiny = ['refer_to_institution', 'refer_branch_office_task'].includes(task.kind)
  const defaultUserTypes = [defaultUserType, ...userTypes]
  const destinyUserTypes = canSameDestiny
    ? defaultUserTypes
    : defaultUserTypes.filter(userType => userType.id.toString() !== originUserTypeId.toString())

  return (
    <>
      <h3 className="font-bold mb-4">Selecciona el usuario que recibe el trámite y los estados que se generan</h3>
      <div className="flex items-stretch flex-col md:flex-row mb-4">
        <div className="bg-white border border-neutral-300 rounded-lg w-full lg:min-w-120">
          <p className="font-bold mb-2 leading-7 pt-4 px-4 md:px-6">
            {getOriginAndDestinySectionLabel('origin', task.kind)}
          </p>
          <div className="flex items-center bg-indigo-700 text-white font-bold mb-4 h-11 px-4 mx-4 md:mx-6">
            {task.origin_user_type_name}
          </div>
          <StateSection
            name="origin_state_id"
            label={
              <>
                Estado al enviar
                <LightTooltip
                  title={
                    <>
                      <p className="mb-2">
                        Es el estado al que cambiará la solicitud para el usuario que realiza la tarea y envía el
                        trámite.
                      </p>
                      <p>
                        Ejm: cuando un funcionario deriva una solicitud, esta cambia a
                        <span className="font-bold ml-1">Derivado.</span>
                      </p>
                    </>
                  }
                  placement="right"
                >
                  <InfoIcon className="ml-2" />
                </LightTooltip>
              </>
            }
          />
        </div>

        <div className="flex items-center justify-center">
          <Svg src={arrowRight} className="mx-4 hidden md:inline" />
          <Svg src={arrowDown} className="my-4 md:hidden" />
        </div>

        <div className="bg-white border border-neutral-300 rounded-lg w-full lg:min-w-120">
          <SelectField
            label={
              <>
                {getOriginAndDestinySectionLabel('destiny', task.kind)}
                <LightTooltip
                  title={
                    <>
                      <p className="mb-2">Es el usuario que recibe el trámite al completarse la tarea.</p>
                      <p>
                        Ejm: al realizar una derivación de solicitud de Acceso a Información Pública, el usuario que la
                        recibe es el
                        <span className="font-bold ml-1">Revisor del Área Poseedora.</span>
                      </p>
                    </>
                  }
                  placement="right"
                >
                  <InfoIcon className="ml-2" />
                </LightTooltip>
              </>
            }
            name="destiny_user_type_id"
            options={dataToOptions(destinyUserTypes)}
            containerClassName="mb-4 pt-4 px-4 md:px-6"
            size="full"
            validate={validations.required()}
            disabled={isEnablePayment(task)}
            emptyLabel="Selecciona el actor"
            onChange={() => change('enabled_tasks', null)}
          />
          <StateSection
            name="destiny_state_id"
            label={
              <>
                Estado al recibir
                <LightTooltip
                  title={
                    <>
                      <p className="mb-2">
                        Es el estado al que cambiará la solicitud para el usuario que recibe el trámite.
                      </p>
                      <p>
                        Ejm: cuando un funcionario recibe una derivación de solicitud, esta debe tener un estado de
                        <span className="font-bold ml-1">Nuevo.</span>
                      </p>
                    </>
                  }
                  placement="right"
                >
                  <InfoIcon className="ml-2" />
                </LightTooltip>
              </>
            }
          />
        </div>
      </div>
    </>
  )
}
export default OriginAndDestinySection

OriginAndDestinySection.propTypes = {
  task: PropTypes.object.isRequired
}
