import PropTypes from 'prop-types'
import React from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { clearMutator, customMoveMutator, customSwapMutator, markForDestroyMutator } from '../../../utils/formMutators'
import pageMutators, { markForDestroyPageMutator } from '../../../utils/pageMutators'
import { focusOnFirstError } from '../../../utils/decorators'
import FormSection from '../../molecules/FormSection'
import FieldsSeparator from '../../atoms/FieldsSeparator'
import StepsFields from '../forms/StepsFields'
import PrivacyPolicyCreator from '../forms/PrivacyPolicyCreator'
import { Button } from '../../atoms/Button'
import { setupSerializedFormData } from '../../../configs/formsConfig'
import EnabledProcedureManagementsFormSetup from './EnabledProcedureManagementsFormSetup'
import { instructions } from '../../../configs/enabledProcedureManagementsConfig'
import Instructions from '../../molecules/Instructions'

const EnabledProcedureManagementsForm = ({ initialValues, config, onSave, onGoBack }) => {
  const {
    add_questions: addQuestions,
    sort_questions: sortQuestions,
    edit_delete_questions: editDeleteQuestions
  } = config
  const handleSubmit = async values => {
    const formData = setupSerializedFormData(values)
    try {
      await onSave(formData)
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        mutators={{
          ...arrayMutators,
          swap: customSwapMutator,
          move: customMoveMutator,
          clear: clearMutator,
          markForDestroy: markForDestroyMutator,
          markForDestroyPage: markForDestroyPageMutator,
          ...pageMutators
        }}
        decorators={[focusOnFirstError]}
      >
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <EnabledProcedureManagementsFormSetup initialValues={initialValues} config={config} />
            <FormSection title="Preguntas del formulario">
              <Instructions list={instructions} />
              <FieldsSeparator />
              <StepsFields
                addQuestions={addQuestions}
                addPages={addQuestions}
                sortQuestions={sortQuestions}
                editDeleteQuestions={editDeleteQuestions}
              />
              <FieldsSeparator />
              <PrivacyPolicyCreator exampleFileUrl={config?.example_privacy_policy?.url} />
              <div className="footer flex flex-col-reverse md:flex-row justify-end py-4 px-4 md:px-0">
                <Button variant="secondary" className="md:max-w-56 lg:max-w-72 md:mr-3" size="full" onClick={onGoBack}>
                  Regresar
                </Button>
                <Button
                  type="submit"
                  className="md:max-w-56 lg:max-w-72 mb-3 md:mb-0"
                  size="full"
                  disabled={submitting}
                >
                  Guardar y continuar
                </Button>
              </div>
            </FormSection>
          </form>
        )}
      </Form>
    </>
  )
}
export default EnabledProcedureManagementsForm

EnabledProcedureManagementsForm.propTypes = {
  config: PropTypes.object,
  initialValues: PropTypes.object,
  onGoBack: PropTypes.func,
  onSave: PropTypes.func
}
