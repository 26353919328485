import PropTypes from 'prop-types'
import React from 'react'
import QuestionComponentMounter from '../question_types_admin/QuestionComponentMounter'
import clsx from 'clsx'

export const AnswerLines = ({ data, dispatch = 'Answer', subChildrenClassName }) => {
  return data.map((answerLine, key) => {
    if (!answerLine) return null
    return (
      <QuestionComponentMounter
        question={answerLine.question}
        dispatch={dispatch}
        kind={answerLine.question.kind}
        key={key}
        answer={answerLine.response}
      >
        {answerLine.sub_answers?.length ? (
          <ol className="list-inside list-sub_index-decimal-bold">
            {answerLine.sub_answers.map((childAnswerLine, key) => {
              return (
                <div className={clsx('pl-6', subChildrenClassName)} key={key}>
                  <QuestionComponentMounter
                    answer={childAnswerLine.response}
                    question={childAnswerLine.question}
                    dispatch={dispatch}
                    kind={childAnswerLine.question.kind}
                  />
                </div>
              )
            })}
          </ol>
        ) : null}
      </QuestionComponentMounter>
    )
  })
}

AnswerLines.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.string,
  subChildrenClassName: PropTypes.string
}
