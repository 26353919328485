import React from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import FormsHeader from '../../../molecules/FormsHeader'
import axios from 'axios'
import { formSteps } from '../../../../configs/enabledProcedureManagementsConfig'
import Preview from '../../../templates/Preview'
import TabContextPreview from '../../../organisms/procedure_managements/TabContextPreview'
import useUserPermission from '../../../../hooks/useUserPermission'
import i18n from 'i18next'

const fetchEnabledProcedureManagement = (id, locale = 'es') =>
  axios.get(`/admin/v1/enabled_procedure_managements/${id}.json?locale=${locale}`).then(a => a.data)

const updatePublished = id => values => axios.put(`/admin/v1/enabled_procedure_managements/${id}.json`, values)

const fetchUserTypes = id =>
  axios.get(`/admin/v1/enabled_procedure_managements/${id}/user_types.json`).then(res => res.data)

const EnabledProcedureManagementsPreview = () => {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const { canManageEnabledProcedureManagements } = useUserPermission('procedureManagement')
  const { data: enabledProcedureManagement, status } = useQuery(
    ['enabled_procedure_management', id, i18n.language],
    () => fetchEnabledProcedureManagement(id, i18n.language),
    { refetchOnWindowFocus: false }
  )
  const { data: userTypes, status: statusUserTypes } = useQuery(['user_types', id], () => fetchUserTypes(id), {
    refetchOnWindowFocus: false
  })
  const { mutate } = useMutation(updatePublished(id), {
    onSuccess: () => queryClient.invalidateQueries('enabled_procedure_management')
  })

  if (status !== 'success' || statusUserTypes !== 'success') return null
  const urlForm = `${window.location.origin}/t/${enabledProcedureManagement.form.id}`
  return (
    <Preview
      steps={
        <FormsHeader
          title="Habilitar una gestión de trámite"
          hint="Configura la gestión de trámite que deseas habilitar para tu institución."
          size="l"
          steps={formSteps}
          activeStep={formSteps.PREVIEW}
        />
      }
      data={enabledProcedureManagement}
      urlToCopy={urlForm}
      publishedUpdate={mutate}
      informationText={{
        linkTitle: 'Enlace del trámite',
        linkHint:
          'Después de publicar el formulario, comparte este enlace con las personas para que la usen. Cuando publiques el trámite, se enviará un correo al personal de tu entidad que participa en la atención sobre sus roles.',
        previewTitle: 'Estas son las vistas de tu trámite para cada tipo de usuario'
      }}
      edit={
        canManageEnabledProcedureManagements && {
          url: `/gestiones-de-tramites-habilitadas/${id}/editar`,
          buttonLabel: 'Editar gestión de trámite'
        }
      }
      allowPublishment={false}
    >
      <TabContextPreview userTypes={userTypes} form={enabledProcedureManagement.form} />
    </Preview>
  )
}
export default EnabledProcedureManagementsPreview
