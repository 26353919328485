import PropTypes from 'prop-types'
import PageHeading from '../../molecules/PageHeading'
import PageBody from '../../molecules/PageBody'
import React from 'react'
import LaunchIcon from '@material-ui/icons/Launch'
import CollaboratorTable from './CollaboratorTable'

const CollaboratorPage = ({ enabledProcedureManagements, onFilter, initialFilters, options }) => {
  return (
    <>
      <PageHeading
        title={<span className="flex items-center">Gestión de trámites</span>}
        pageDescription="Estos son los trámites a los que tienes acceso."
        linksSection={
          <a className="flex" href="gestiones-de-tramites/que-es-un-gestor-de-tramites" target="_blank">
            <p className="font-bold mx-auto md:ml-0 text-lg">
              ¿Qué es una gestión de trámite? <LaunchIcon className="ml-2 mb-1" />
            </p>
          </a>
        }
      />
      <PageBody
        results={enabledProcedureManagements}
        onFilter={onFilter}
        initialFilters={initialFilters}
        optionsOrderBy={options}
      >
        <CollaboratorTable enabledProcedureManagements={enabledProcedureManagements.data} />
      </PageBody>
    </>
  )
}

export default CollaboratorPage

CollaboratorPage.propTypes = {
  enabledProcedureManagements: PropTypes.object,
  initialFilters: PropTypes.object,
  onFilter: PropTypes.func.isRequired,
  options: PropTypes.array
}
