import React, { useState } from 'react'

import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import Container from '../../../atoms/Container'
import { createHeaderInfo, formSteps } from '../../../../configs/procedureManagementsConfig'
import FormsHeader from '../../../molecules/FormsHeader'
import { Button } from '../../../atoms/Button'
import ConceptualMap from '../../../organisms/procedure_managements/ConceptualMap'
import { Link, useLocation, useParams } from 'react-router-dom'
import { ExampleModal } from '../../../molecules/dialogs/ExampleModal'
import tasksImage from '../../../../images/procedure_managements/examples/tasks_image.jpg'
import tasksMobileImage from '../../../../images/procedure_managements/examples/tasks_mobile_image.jpg'
import userTypesImage from '../../../../images/procedure_managements/examples/actors_image.jpg'
import userTypesMobile from '../../../../images/procedure_managements/examples/actors_mobile.jpg'
import rulesImage from '../../../../images/procedure_managements/examples/rules_image.jpg'
import { ProcedureManagementContext } from '../../../../utils/contexts'

const ProcedureManagementsUserTypesTasksConnections = () => {
  const { search } = useLocation()
  const { id: procedureManagementId } = useParams()
  const [showUserModal, setShowUserModal] = useState(false)
  const [showTasksModal, setShowTasksModal] = useState(false)
  const [showRulesModal, setShowRulesModal] = useState(false)
  return (
    <>
      <PageNavigation />
      <Container className="py-12">
        <FormsHeader
          title={createHeaderInfo.title}
          hint={createHeaderInfo.hint}
          size="l"
          steps={formSteps}
          activeStep={formSteps.USER_TYPES_TASKS_CONNECTIOS}
          description={
            <>
              <p>
                Crea los actores que participarán en la atención del trámite, las tareas que realizarán y la secuencia o
                conexión entre ellas.
              </p>
              <ul className="mt-4">
                <li className="list-inside list-decimal mb-3 md:mb-1">
                  Agrega los <strong>actores</strong> que participarán en la gestión del trámite.{' '}
                  <button className="text-blue-700 font-bold" onClick={() => setShowUserModal(true)} type="button">
                    Revisa el ejemplo.
                  </button>
                </li>
                <li className="list-inside list-decimal mb-3 md:mb-1">
                  Crea las <strong>tareas</strong> que podrán realizar estos usuarios para la gestión de trámite.{' '}
                  <button className="text-blue-700 font-bold" onClick={() => setShowTasksModal(true)} type="button">
                    Revisa el ejemplo.
                  </button>
                </li>
                <li className="list-inside list-decimal">
                  Define las <strong>conexiones</strong> entre las tareas y, de requerirlo, sus reglas adicionales.{' '}
                  <button className="text-blue-700 font-bold" onClick={() => setShowRulesModal(true)} type="button">
                    Revisa el ejemplo.
                  </button>
                </li>
              </ul>
            </>
          }
        />
        <ExampleModal
          onClose={() => setShowUserModal(false)}
          show={showUserModal}
          desktopImage={userTypesImage}
          mobileImage={userTypesMobile}
          title="Actores"
        >
          Se refiere a los actores que participan en la gestión del trámite: solicitante y el personal de la entidad que
          atiende la solicitud. Por ejemplo, en el trámite de acceso a la información pública, hay 3 involucrados:
        </ExampleModal>
        <ExampleModal
          onClose={() => setShowTasksModal(false)}
          show={showTasksModal}
          desktopImage={tasksImage}
          mobileImage={tasksMobileImage}
          title="Tarea"
        >
          Una tarea es un paso en la gestión de un trámite. Por ejemplo, en el trámite de acceso a la información
          pública, el Frasaip deriva una solicitud a un Frapi para que este último recolecte la información requerida.
        </ExampleModal>
        <ExampleModal
          onClose={() => setShowRulesModal(false)}
          show={showRulesModal}
          desktopImage={rulesImage}
          mobileImage={rulesImage}
          title="Conexiones y reglas adicionales"
          mobileScrollable
        >
          <p className="mb-4">
            Son las reglas que definen cuándo y a quién se le habilitan las tareas, si es que estas deben completarse en
            un tiempo determinado y si es que hay alguna condición especial que se deba tomar en cuenta.
          </p>
          <p>
            Por ejemplo, para la tarea {'"'}Solicitar subsanación a solicitante{'"'} en el trámite de acceso a la
            información pública, se consideran las siguientes reglas:
          </p>
        </ExampleModal>
        <div className="bg-neutral-100 overflow-x-auto p-4 md:p-10 mb-6">
          <ProcedureManagementContext.Provider value={procedureManagementId}>
            <ConceptualMap />
          </ProcedureManagementContext.Provider>
        </div>
        <div className="flex flex-col-reverse md:flex-row justify-end py-4 px-4 md:px-0">
          <Button
            el={Link}
            to={`/gestiones-de-tramites/${procedureManagementId}/formulario-inicial${search}`}
            variant="secondary"
            className="md:max-w-56 lg:max-w-72 md:mr-3"
            size="full"
          >
            Regresar
          </Button>
          <Button
            el={Link}
            to={`/gestiones-de-tramites/${procedureManagementId}/vista-previa${search}`}
            className="md:max-w-56 lg:max-w-72 mb-3 md:mb-0"
            size="full"
          >
            Guardar y continuar
          </Button>
        </div>
      </Container>
    </>
  )
}
export default ProcedureManagementsUserTypesTasksConnections
