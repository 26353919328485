import React, { useMemo } from 'react'
import Container from '../../../atoms/Container'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import FormsForm from '../../../organisms/forms/FormsForm'
import useInstitution from '../../../../hooks/useInstitution'
import { initialValuesFromTemplate } from '../../../../configs/formsConfig'
import HeaderCreateForm from '../../../organisms/forms/HeaderCreateForm'
import useTemplate from '../../../../hooks/useTemplate'
import PropTypes from 'prop-types'

const createForm = ({ values, config }) => axios.post('/admin/v1/form_test.json', values, config)

const FormsNewFromTemplate = ({ templateId, isPreview = false }) => {
  const { template_id: paramTemplateId } = useParams()
  if (paramTemplateId) templateId = paramTemplateId
  const history = useHistory()
  const handleFormCreate = async ({ values, config }) => {
    try {
      const { data } = await createForm({ values, config })
      history.push(`/formularios/${data.id}/preguntas?action=new`)
    } catch (error) {
      alert('Ocurrió un error al intentar guardar.')
    }
  }
  const { getInstitution } = useInstitution()
  const { data: institution, status: institutionStatus } = getInstitution()
  const { data: template, status: templateStatus } = useTemplate(templateId)
  const initialValues = useMemo(() => {
    if (templateStatus === 'success') return initialValuesFromTemplate(template)
  }, [templateStatus])
  if (templateStatus !== 'success' || institutionStatus !== 'success') return null
  const config = { ...template, from_template: true, enabled_payment_methods: institution.enabled_payment_methods }
  const mutateForm = values => (isPreview ? () => null : handleFormCreate(values))

  return (
    <>
      <PageNavigation />
      <Container className="py-12">
        <HeaderCreateForm
          headerParams={{
            activeStep: 1,
            hint: 'Revisa el formulario y modifícalo si la plantilla lo permite.'
          }}
        />
        <FormsForm initialValues={initialValues} config={config} mutateForm={mutateForm} noPristineToDisable />
      </Container>
    </>
  )
}

FormsNewFromTemplate.propTypes = {
  templateId: PropTypes.string,
  isPreview: PropTypes.bool
}

export default FormsNewFromTemplate
