import { FormControl } from '@material-ui/core'
import axios from 'axios'
import React from 'react'
import useQuery, { isLoading } from '../../../hooks/useQuery'
import DateRangeField from '../../molecules/fields/DateRangeField'
import PublishedFilter from '../filters/PublishedFilter'
import SelectFieldFilter from '../../molecules/filters/SelectFieldFilter'

const fetchProcedureManagements = () => axios.get(`/admin/v1/procedure_managements/all.json`).then(res => res.data)
const EnabledProcedureManagementsFilter = () => {
  const { data: procedureManagements, status } = useQuery(['procedureManagements'], fetchProcedureManagements)

  if (isLoading(status)) return null
  return (
    <div className="flex md:flex-row w-full flex-col p-4">
      <FormControl component="fieldset" classes={{ root: 'block md:flex' }}>
        <SelectFieldFilter
          label="Trámite"
          options={procedureManagements}
          name="procedure_management_id"
          emptyLabel="Selecciona un trámite"
          className="md:w-60"
        />
      </FormControl>
      <FormControl component="fieldset" classes={{ root: 'md:pl-14' }}>
        <PublishedFilter />
      </FormControl>
      <FormControl component="fieldset" classes={{ root: 'mt-6 md:pl-14 md:mt-0' }}>
        <DateRangeField name="enabled_date" label="Fecha de habilitación:" />
      </FormControl>
    </div>
  )
}

export default EnabledProcedureManagementsFilter
