import React, { Fragment, useEffect, useState } from 'react'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Container from '../../../atoms/Container'
import SelectField from '../../../molecules/fields/SelectField'
import { Form } from 'react-final-form'
import TextField from '../../../molecules/fields/TextField'
import * as validations from '../../../../utils/validations'
import WhenFieldChanges from '../../../molecules/WhenFieldChanges'
import { Button } from '../../../atoms/Button'
import Value from '../../../molecules/Value'
import { TagBox } from '../../../molecules/TagBox'
import { useParams, Link } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import axios from 'axios'
import { PageNavigation } from '../../../organisms/navigation/PageNavigation'
import { PaymentAnswer } from '../../../organisms/answers/PaymentAnswer'
import { useAnswer } from '../../../../hooks/useAnswer'
import Alert from '../../../molecules/Alert'
import TimelineStates from '../../../organisms/answers/TimelineStates'
import { useAnswerToWebhook } from '../../../../hooks/useAnswerToWebhook'
import { AnswerLines } from '../../../organisms/answers/AnswerLines'
import DownloadPDFButton from '../../../organisms/answers/DownloadPDFButton'
import AnswerApiStatus from '../../../organisms/answers/AnswerApiStatus'
import ToastAlert from '../../../molecules/ToastAlert'

const textAreaLabel = {
  ATENDIDO: 'atendido',
  RECHAZADO: 'rechazado',
  'EN PROCESO': 'proceso'
}

const mutateAnswer = ({ values, formId, answerId }) =>
  axios.put(`/admin/v1/forms/${formId}/answers/${answerId}.json`, values)

const Answer = () => {
  const { form_id: formId, answer_id: answerId } = useParams()
  const { data: answerDataServer, isLoading } = useAnswer()
  const { answer, onResend } = useAnswerToWebhook({ answer: answerDataServer, formId })
  const [showFeedback, setShowFeedback] = useState(false)

  const handleCloseFeedback = () => setShowFeedback(false)
  useEffect(() => handleCloseFeedback(), [answerId])

  const queryClient = useQueryClient()
  const { mutate } = useMutation(mutateAnswer, {
    onSuccess: () => {
      queryClient.invalidateQueries([formId, answerId, 'answer'])
      setShowFeedback(true)
    }
  })

  if (!answer || isLoading) return null

  const initialValues = {
    status: {
      label: '',
      comment: null
    }
  }

  const handleSubmit = values => mutate({ values, formId, answerId })

  return (
    <div>
      <PageNavigation breadcrumbData={answer.form} />
      <Container>
        <div className="flex justify-between items-center mb-12 mt-4">
          <h1 className="font-bold text-3xl">
            Respuesta {answer.current_index + 1} de {answer.total_count}
          </h1>
          <nav className="flex">
            {answer.current_index ? (
              <Button
                type="button"
                className="text-left leading-snug"
                el={Link}
                to={`/formularios/${formId}/respuestas/${answer.previous}`}
                color="secondary"
                variant="text"
                startIcon={<NavigateBeforeIcon color="secondary" style={{ fontSize: '2.5rem' }} />}
              >
                Anterior <br /> Respuesta
              </Button>
            ) : null}
            {answer.current_index + 1 !== answer.total_count ? (
              <Button
                type="button"
                className="text-left leading-snug"
                el={Link}
                to={`/formularios/${formId}/respuestas/${answer.next}`}
                color="secondary"
                variant="text"
                endIcon={<NavigateNextIcon color="secondary" style={{ fontSize: '2.5rem' }} />}
              >
                Siguiente <br /> Respuesta
              </Button>
            ) : null}
          </nav>
        </div>
        <div className="form-information border border-solid border-neutral-400 flex flex-wrap sm:min-h-140 mb-8">
          <div className="w-full sm:w-7/12 p-4 md:p-6">
            <div className="flex flex-col md:flex-row md:items-end justify-between">
              <div className="text-lg">
                <div className="font-bold">Código:</div>
                <span className="text-indigo-700 font-bold text-3xl">{answer.hashid}</span>
              </div>
              <div className="mt-3">
                <p className="md:text-right">
                  <span className="font-bold">Fecha de envío: </span>
                  <span>{answer.created_at}</span>
                </p>
              </div>
            </div>
            <hr className="my-4 border" />
            {answerDataServer?.form.has_webhook && <AnswerApiStatus answerData={answer} onResend={onResend} />}
            <DownloadPDFButton full className="mt-4 flex justify-end" answerId={answerId} formId={formId} />
            <ol className="list-inside list-sub_index-decimal-bold">
              <AnswerLines data={answer.tree} subChildrenClassName="mt-3" />
              {answer.selected_kind_payment && <PaymentAnswer formData={answer} />}
            </ol>
            {answer.selected_kind_payment ? (
              <Alert className="mt-10">
                Toma en cuenta que es responsabilidad de la entidad y del usuario encargado del trámite o servicio
                verificar la información recibida y el cumplimiento de la normatividad vigente, por lo que cada entidad
                usuaria de FACILITA Perú es responsable de lo ocurrido.
              </Alert>
            ) : null}
          </div>
          <div className="w-full sm:w-5/12 bg-neutral-200 p-4 md:p-6">
            <div className="text-right text-lg mb-6">
              Estado: <TagBox label={answer.status.label} size="lg" />
            </div>

            <ToastAlert
              alertText="Cambio de estado realizado exitosamente."
              type="success"
              open={showFeedback}
              onClose={handleCloseFeedback}
            />
            <Form
              onSubmit={handleSubmit}
              subscription={{ pristine: true, submitting: true }}
              initialValues={initialValues}
            >
              {({ handleSubmit, submitting, pristine }) => (
                <form className="" onSubmit={handleSubmit}>
                  <SelectField
                    name="status.label"
                    label="Cambiar estado:"
                    options={[
                      { value: 'ATENDIDO', label: 'Atendido' },
                      { value: 'RECHAZADO', label: 'Rechazado' },
                      { value: 'EN PROCESO', label: 'En proceso' }
                    ]}
                  />
                  <WhenFieldChanges field="status.label" set="status.comment" to={null} />
                  <Value name="status.label">
                    {({ value }) =>
                      value && (
                        <Fragment>
                          <p className="mt-4 font-bold pb-2">
                            Explica el motivo por el cual la solicitud está en {textAreaLabel[value]}
                          </p>
                          {answer.form.is_trackable ? (
                            <ul className="list-inside list-disc mb-4">
                              <li>El cambio de estado será actualizado y mostrado al ciudadano.</li>
                              <li>
                                La notificación no se enviará; se quedará guardada como historial para uso interno de tu
                                institución.
                              </li>
                              <li>Deja un mensaje para el ciudadano informado sobre el estado de su solicitud.</li>
                            </ul>
                          ) : (
                            <span className="inline-block mb-4">
                              La notificación no se enviará; se quedará guardada como historial para uso interno de tu
                              institución.
                            </span>
                          )}

                          <TextField
                            name="status.comment"
                            element="textarea"
                            validate={validations.required()}
                            className="sm:max-w-none h-40 rounded-lg"
                            rootClassName="text-sm"
                            label="Notificación para control interno"
                            rows="4"
                            size="full"
                          />
                          {answer.form.is_trackable && (
                            <TextField
                              name="status.citizen_comment"
                              element="textarea"
                              className="sm:max-w-none h-40 rounded-lg"
                              rootClassName="text-sm"
                              label="Mensaje para el ciudadano"
                              rows="4"
                              size="full"
                            />
                          )}
                          <Button type="submit" disabled={pristine || submitting}>
                            Actualizar estado
                          </Button>
                        </Fragment>
                      )
                    }
                  </Value>
                </form>
              )}
            </Form>
            {answer.status.label !== 'NUEVO' && <TimelineStates statusHistory={answer.status_history} />}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Answer
